import React from 'react'
import Layout from 'layouts/en'
import styled, { css } from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button, Input, FullWidthBg } from 'components'
import { TryOrSchedule } from "containers/en";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import Island from "images/BookingBurial8bitcopy.png";
import Phone from '../images/phone.jpg'
import messages from '../images/messages.png'
import BALogo from '../images/ba-logo.png'
import photos from '../images/photos.jpg'
import BareMorning from '../images/morning.svg'
import BareLunch from '../images/lunch.svg'
import BareAfternoon from '../images/afternoon.svg'
import BareEvening from '../images/evening.svg'
import Moment from 'react-moment'
import BareAdd from 'images/add.svg'
import { format } from 'utils'

const formats = format('en')

const Now = styled(Div)`
  border-bottom: 2px solid ${design.color.primary};
  &:after {
    content: "";
    display: block;
    background: ${design.color.primary};
    margin-bottom: -${({ s }) => (s+1)/2}px;
    width: ${({ s }) => s}px;
    height: ${({ s }) => s}px;
    border-radius: 50%;
  }
`
const Line = styled(Div)`
  border-bottom: 1px solid ${design.color.grayLight};
  height: 1px;
`

const svgs = css`
  width: 100%;
  height: auto;
`
const Morning = styled(BareMorning)`${svgs}`
const Lunch = styled(BareLunch)`${svgs}`
const Afternoon = styled(BareAfternoon)`${svgs}`
const Evening = styled(BareEvening)`${svgs}`

const Add = styled(BareAdd)`
  
`

export default () => {
  return (
    <Layout>
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={Island} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Bury no-shows, not your customers</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[3,6]}>Significantly reduce no-shows with automated reminders. Access your calendar anywhere, anytime. Offer 24/7 online reservation.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.com/go" method="get">
              <Div display={['block','flex']}>
                <Input name="email" fontSize={[4,6]} p={[3,4]} placeholder={`Your email address`} /><Button width={['100%','auto']} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Get Started</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Easy setup • Free for starters • No credit card required • Cancel any time</Text>
          </Div>
        </Div>
      </Box>

      <Div tag={'section'}>
        <Div id={'Schedule'} display={'flex'} mb={[5,7]}>
          <Title href={'/en/schedule#Schedule'} mr={7} fontSize={[5,8]} fontWeight={700}>Schedule</Title>
        </Div>

        <Div id={'Plan'} display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title href={'/en/schedule#Plan'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Plan</Title>
              <Title href={'/en/schedule#Remind'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Remind</Title>
              <Title href={'/en/schedule#Boost'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Boost</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Optimise the management of your appointments and your personal schedule</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>View your calendar on your phone, so you’re in the know on the go.</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box position={'relative'} bg={'white'} m={0} mx={[-36,0]} p={[4,5]} display={'flex'}>
              <Div flex={1}>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>9:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>10:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>11:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>12:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>13:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>14:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>15:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>16:00</Text>
              </Div>
              <Div flex={4} position={'relative'}>
                <Div position={'absolute'} zIndex={1} top={0} left={0} width={'100%'} pt={['12px','19px']}>
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line />
                </Div>
                <Div position={'absolute'} zIndex={2} top={0} left={0} width={'100%'} pt={['12px','19px']}>
                  <Div height={[65,101]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'grayLight'}>
                    <Text mb={[1]} fontSize={[3,6]} fontWeight={700}>Manuel Genovese</Text>
                    <Text fontSize={[3,6]}>Acupuncture</Text>
                  </Div>
                  <Now display={['block','none']} s={9} mt={[19,30]} mb={[14,22]} />
                  <Now display={['none','block']} s={13} mt={[19,30]} mb={[14,22]} />
                  <Div height={[91,143]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                    <Text mb={[1]} fontSize={[3,6]} fontWeight={700}>Leah Dresner</Text>
                    <Text fontSize={[3,6]}>Acupuncture - First session</Text>
                  </Div>
                  <Div height={[64,99]} mt={`1px`} borderRadius={5} color={'white'} p={['6px','8px']} bg={'#5798A1'}>
                    <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>Lunch with Theodor Darius</Text>
                    <Text fontSize={[1,4]}>Miki Ramen - Zurich, Switzerland</Text>
                  </Div>
                  <Div display={'flex'}>
                    <Div flex={1}>
                      <Div height={[64,100]} mt={`1px`} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text whiteSpace={'nowrap'} mb={[1]} fontSize={[1,4]} fontWeight={700}>Viviana Lori</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                      <Div height={[64,100]} mt={[2,3]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>Millard Denis</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                      <Div height={[64,100]} mt={[2,3]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>David Freud</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                    </Div>
                    <Div flex={1} mt={6} ml={[3,4]}>
                      <Div height={[64,100]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text whiteSpace={'nowrap'} mb={[1]} fontSize={[1,4]} fontWeight={700}>Monica Padovano</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                      <Div height={[64,100]} mt={[2,3]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>Suzette Leroy</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Div bottom={[24,36]} right={[24,36]} position={'absolute'} zIndex={3} width={[30,48]} height={[30,48]} borderRadius={5} color={'white'} bg={'primary'} boxShadow={'primary'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Add />
              </Div>
            </Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Schedule appointments</Title>
            <Text fontSize={[2,4]}>Clean and simple calendar that can handle both your personal and business schedule.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Reduce grunt work</Title>
            <Text fontSize={[2,4]}>Reservations not only fill your calendar, but they also update your clients’ records, and your finance automatically.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Accessible anywhere</Title>
            <Text fontSize={[2,4]}>Just go to stairlin.app from any browser on your smartphone, computer, or tablet.</Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div className={'fw'} position={'relative'} p={[36,6]} pt={[6,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/en/schedule#Plan'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Plan</Title>
            <Title id={'Remind'} href={'/en/schedule#Remind'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Remind</Title>
            <Title href={'/en/schedule#Boost'} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Boost</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>No-shows belong to the past</Title>
          <Text mb={[]} fontSize={[3,4]}>Significantly reduce no-shows with automated reminders.</Text>
          <Div mx={[-36,0]} display={['block','flex']} mt={[6,110]}>
            <Div flex={1} mb={6}>
              <Title mb={[3,5]} fontSize={[3,6]}>SMS / WhatsApp</Title>
              <Box boxShadow={'primaryDark'} position={'relative'} width={375} height={812} bg={'white'} backgroundImage={`url('${Phone}')`} backgroundSize={'cover'} m={0} mx={[0,8]} p={[4,6]}>
                <Div position={'absolute'} top={14} left={14} fontSize={15}>
                  <Moment format={`HH:mm`} />
                </Div>
                <Div position={'absolute'} top={125} right={0} left={0} fontSize={80}>
                  <Moment format={`HH:mm`} />
                </Div>
                <Div position={'absolute'} top={210} right={0} left={0} fontSize={22}>
                  <Moment format={`dddd, D MMMM`}/>
                </Div>
                <Div position={'absolute'} top={264} left={20} fontSize={28}>
                  Today
                </Div>
                <Div p={12} position={'absolute'} width={359} borderRadius={12} bg={`rgba(255,255,255,.5)`} top={301} left={8}>
                  <Div fontSize={15} color={`rgba(0,0,0,.4)`} display={'flex'} justifyContent={'space-between'}>
                    <Div display={'flex'}>
                      <img width={20} height={20} src={messages} alt=""/> <Text ml={`7px`}>Message</Text>
                    </Div>
                    <Text>5min ago</Text>
                  </Div>
                  <Text color={'text'} mt={`8px`} textAlign={'left'}>Get ready! Your reservation at “Blossom Acupuncture” is tomorrow at 4:30 PM. https://stairl.in/Fo0B4R - Stairlin</Text>
                </Div>
              </Box>
            </Div>
            <Div display={['none','block']} flex={1}>
              <Title mb={[3,5]} fontSize={[3,6]}>Email</Title>
              <Box boxShadow={'primaryDark'} textAlign={'left'} color={'text'} bg={'white'} m={0} p={[4,6]}>
                <Text fontWeight={700} mb={2} fontSize={6}>Get ready!</Text>
                <Text fontSize={5}>It’s almost time for your appointment.</Text>

                <Div my={4}>
                  <img width={'100%'} src={photos} alt=""/>
                </Div>

                <Div mb={6} display={'flex'}>
                  <img width={64} height={64} src={BALogo} alt=""/>
                  <Div ml={4}>
                    <Text fontSize={6} fontWeight={700}>Blossom Acupuncture</Text>
                    <Text fontSize={4}>Lausanne - Switzerland</Text>
                  </Div>
                </Div>

                <Div mb={5} display={'flex'} justifyContent={'space-between'} fontSize={6}>
                  <Div>
                    <Moment add={{ d:1 }} format={`dddd`} /><br/>
                    <Moment add={{ d:1 }} format={`MMMM D, YYYY`} />
                  </Div>
                  <Div textAlign={'right'}>
                    4:30 PM<br/>
                    to 5:30 PM
                  </Div>
                </Div>

                <Div display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={4}>Acupuncture</Text>
                  <Text fontSize={4}>CHF 120.00</Text>
                </Div>

                <Button py={3} mt={5} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={4}>Get Directions</Button>

              </Box>
            </Div>
          </Div>

          <Div textAlign={'left'} display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Multi-channel notifications</Title>
              <Text fontSize={[2,4]}>Automatic reminders across multiple channels. Support includes email, SMS, and WhatsApp.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Confirmation Page</Title>
              <Text fontSize={[2,4]}>All they need to show up at the
                right time and the right place.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Cancellation & Rescheduling</Title>
              <Text fontSize={[2,4]}>When a customer can’t show up, the online booking system automatically frees the reserved slot, making it available for someone else.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Boost'} mt={[6,8]}>
          <Div display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/schedule#Plan'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Plan</Title>
                <Title href={'/en/schedule#Remind'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Remind</Title>
                <Title href={'/en/schedule#Boost'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={700}>Boost</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Grow your business,
                  even while you sleep</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Your online assistant is working 24/7 to fill your schedule.</Text>
              </Div>
            </Div>
            <Div mx={[-36,0]} flex={1}>
              <Box bg={'white'} width={['100%',384]} m={0} p={[6]}>
                <Text fontWeight={700} fontSize={[4,5]}>What time works for you?</Text>
                <Div py={4} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Morning />
                    <Text fontWeight={700} fontSize={2} my={3}>Morning</Text>
                  </Div>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Lunch />
                    <Text style={{ whiteSpace: 'nowrap'}} fontWeight={700} fontSize={2} my={3}>Lunch time</Text>
                  </Div>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Afternoon />
                    <Text fontWeight={700} fontSize={2} my={3}>Afternoon</Text>
                  </Div>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Evening />
                    <Text fontWeight={700} fontSize={2} my={3}>Evening</Text>
                  </Div>
                </Div>
                <Button py={3} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={4}>Find availabilities</Button>
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Cut your workload</Title>
              <Text fontSize={[2,4]}>Clients can quickly view your availability and make reservations themselves. They can also reschedule or cancel on any of their devices.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Reach a larger audience</Title>
              <Text fontSize={[2,4]}>Boost your online presence, and keep your customers engaged. More than 80% of people aged between 18 and 25 reserve online in Switzerland.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Collect everything you need</Title>
              <Text fontSize={[2,4]}>Ask clients to fill out intake forms when scheduling, and have all of their information neat & tidy in one place.</Text>
            </Div>
          </Div>
          <Div display={['block','flex']} flexWrap={'wrap'} mt={[4,6]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Set your own rules</Title>
              <Text fontSize={[2,4]}>Choose your own schedule, services available, prices, and requirements for clients to reserve, cancel, and reschedule.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Stay in the know</Title>
              <Text fontSize={[2,4]}>Get notified anytime a new appointment is booked, check your schedule right from your phone.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Offer a great experience</Title>
              <Text fontSize={[2,4]}>Offer a passwordless experience to your customers with a simple phone number verification process that keeps the only the bad guys out.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>

    </Layout>
  )
}
